import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import type React from 'react'
import { type FC } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'app/store/hooks'

import {
  FetchedDescriptionsSlice,
  fetchedItemsSelectors,
} from '../model/searchDataSlice'
import {
  markedDescriptionsSelectors,
  MarkedDescriptionsSlice,
} from '../model/selectableDescriptions'
import { type SelectableDescription } from '../model/types'

import { StatusIcon } from './StatusIcon'

export interface SelectableItem {
  isSelected: boolean
  record: SelectableDescription
}

export const SelectMultipleDescriptions: FC = () => {

  const dispatch = useAppDispatch()

  const fetchedItems: SelectableDescription[] = useSelector(fetchedItemsSelectors.selectAll)
  const selectedValues: SelectableDescription[] = useSelector(markedDescriptionsSelectors.selectAll)

  function getOnChangeSelectedCheckbox (item: SelectableDescription) {
    return () => {
      dispatch(MarkedDescriptionsSlice.actions.removeDescription(item.elementId))
      dispatch(FetchedDescriptionsSlice.actions.addOne({
        elementId: item.elementId,
        unitId: item.unitId,
        techDescriptionStatus: item.techDescriptionStatus,
      }))
    }
  }

  function getOnChangeFoundCheckbox (item: SelectableDescription) {
    return () => {
      dispatch(FetchedDescriptionsSlice.actions.removeOne(item.elementId))
      dispatch(MarkedDescriptionsSlice.actions.addDescription({
        elementId: item.elementId,
        unitId: item.unitId,
        techDescriptionStatus: item.techDescriptionStatus,
      }))
    }
  }

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Switch onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const isAll = event.target.checked
          if (isAll) {
            dispatch(MarkedDescriptionsSlice.actions.addManyDescriptions(fetchedItems))
          }
          if (!isAll) {
            dispatch(FetchedDescriptionsSlice.actions.addMany(selectedValues))
            dispatch(MarkedDescriptionsSlice.actions.removeAllDescriptions())
          }
        }}
        />
        <Typography variant="body1">Select all</Typography>
      </Stack>
      <Divider/>
      <Stack direction="column">
        {selectedValues?.map((item) => {
          return (
            <FormControlLabel
              key={item.elementId}
              control={<Checkbox
                data-testid="selected-unit-id-checkbox"
                checked
                onChange={getOnChangeSelectedCheckbox(item)}
              />}
              label={
                <>
                  <Stack
                    spacing={1}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Typography variant={'overline'}>
                      {item.elementId}
                    </Typography>
                    <StatusIcon status={item.techDescriptionStatus}/>
                  </Stack>
                </>
              }
            />
          )
        })}
      </Stack>

      {selectedValues?.length > 0 && <Divider/>}

      <Stack direction="column">
        {fetchedItems && fetchedItems
          ?.filter(item => !selectedValues
            ?.find(i => i.elementId === item.elementId))
          ?.map((item) => {
            return (
              <FormControlLabel
                key={item.elementId}
                control={<Checkbox
                  data-testid="found-unit-id-checkbox"
                  onChange={getOnChangeFoundCheckbox(item)}
                />}
                label={
                  <Stack
                    spacing={1}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Typography variant={'overline'}>
                      {item.elementId}
                    </Typography>
                    <StatusIcon status={item.techDescriptionStatus}/>
                  </Stack>
                }
              />)
          })}
      </Stack>
    </Box>
  )

}
