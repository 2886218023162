{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */
}
import {Link} from '@mui/material'
import {
  type FunctionComponent,
  type PropsWithChildren,
  useRef,
} from 'react'

import authService from 'shared/api/AuthService'
import { handleErrors } from 'shared/api/errorHandler'
import { msalFetch } from 'shared/api/graph'

interface Props {
  method: 'GET' | 'POST'
  url: string
  filename?: string
  isDownloading?: boolean
  onDownloading?: () => void
  onDownloaded?: () => void
  onError?: (error: Error) => void
}

const AuthenticatedDownloadLink: FunctionComponent<PropsWithChildren<Props>> = (props) => {

  const link = useRef<HTMLAnchorElement>(null)

  const handleDownload = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()

    if (props.isDownloading) {
      return
    }

    if ((link?.current) == null) {
      return
    }

    if (props.onDownloading) {
      props.onDownloading()
    }

    const result = await authService.getIdentity()
    if (!result) {
      return
    }

    const query = async () => {
      const fetch = msalFetch()

      if (props.method === 'POST') {
        return await fetch.post(props.url, result.accessToken)
      }

      return await fetch.get(props.url, result.accessToken)
    }

    query()
      .then((res) => {
        if (res.status === 429) {
          throw new Error('You have reached the limit for downloading KD files')
        }
        return res
      })
      .then(handleErrors)
      .then((result) => {
        const header = result.headers.get('content-disposition')
        const parts = header?.split(';')
        const filename = parts
          ? parts[1].split('=')[1]
          : ''

        result.blob().then((blob) => {
          const href = window.URL.createObjectURL(blob)

          if (link.current != null) {
            link.current.download = props.filename || filename || ''
            link.current.href = href

            link.current.click()

            if (props.onDownloaded) {
              props.onDownloaded()
            }

            link.current.download = ''
            link.current.href = ''
          }
        })
      })
      .catch((error) => {
        if (props.onError) {
          props.onError(error)
        }
      })
  }

  return (
    <>
      <Link
        href=""
        onClick={async (e) => {
          await handleDownload(e)
        }}

      >
        {props.children}
      </Link>
      <a ref={link}/>
    </>
  )
}

export default AuthenticatedDownloadLink
