import { createSlice, createAsyncThunk, SerializedError, type PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../../../../app/store'
import { ApprovedAnalogsResponse } from '../../../../entities/logistics/TechnicalCard/model/types'
import { API_ADDRESS } from '../../../../shared/api/api'
import authService from '../../../../shared/api/AuthService'
import { handleErrors } from '../../../../shared/api/errorHandler'
import { msalFetch } from '../../../../shared/api/graph'

const UNITS_API_ADDRESS = `${API_ADDRESS}/el/units`

export interface SearchAnalogsDialogState {
  open: boolean
  status: 'pending' | 'idle'
  unitId?: number
  error?: SerializedError
}

const initialState: SearchAnalogsDialogState = {
  open: false,
  status: 'idle',
}

export const searchAnalogs = createAsyncThunk(
  'search-analogs',
  async (request: { unitId: number }) => {
    const result = await authService.getIdentity()
    const {
      unitId,
    } = request
    if (result) {
      return await msalFetch()
        .get(`${UNITS_API_ADDRESS}/${unitId}/analogs`, result.accessToken)
        .then(handleErrors)
        .then<ApprovedAnalogsResponse>(async (res) => await res.json())
    }
  },
)

export const searchAnalogsSlice = createSlice({
  name: 'searchAnalogs',
  initialState: initialState,
  reducers: {
    setOpenAnalogs: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    setUnitIdAnalogs: (state, action: PayloadAction<number>) => {
      state.unitId = action.payload
    },
  },
  extraReducers: (build) => {
    build.addCase(searchAnalogs.pending, (state, action) => {
      state.status = 'pending'
    })
    build.addCase(searchAnalogs.fulfilled, (state, action) => {
      state.status = 'idle'
    })
    build.addCase(searchAnalogs.rejected, (state, action) => {
      state.status = 'idle'
    })
  },
})

export const selectOpenSearchAnalogs = (state: RootState) =>
  state.logistics.searchAnalogs.open
export const selectUnitIdSearchAnalogs = (state: RootState) =>
  state.logistics.searchAnalogs.unitId

export const {
  setOpenAnalogs,
  setUnitIdAnalogs,
} = searchAnalogsSlice.actions

export default searchAnalogsSlice.reducer
