import {
  createEntityAdapter,
  createSlice, createAsyncThunk, SerializedError, EntityState, PayloadAction,
} from '@reduxjs/toolkit'

import { type RootState } from 'app/store/store'

import { UnitTechnicalDescription } from '../../../../entities/logistics/TechnicalCard'
import { API_ADDRESS, API_GATEWAY_ADDRESS } from '../../../../shared/api/api'
import authService from '../../../../shared/api/AuthService'
import { handleErrors } from '../../../../shared/api/errorHandler'
import { msalFetch } from '../../../../shared/api/graph'
import { SelectableDescription } from '../../units/searchUnts'

const replicateDescriptionAdapter = createEntityAdapter<SelectableDescription>({ selectId: (description) => description.unitId })
const selectedReplicateDescriptionAdapter = createEntityAdapter<SelectableDescription>({ selectId: (description) => description.unitId })

const REPLICATE_API_ADDRESS = `${API_GATEWAY_ADDRESS}/el/units`

export interface ReplicateDescriptionDialogState {
  open: boolean
  replicationStatus: 'pending' | 'idle'
  descriptions: EntityState<SelectableDescription>
  selectedDescriptions: EntityState<SelectableDescription>
  request: ReplicationRequest
  error?: SerializedError
}

export type ReplicationRequest = {
  materialsReplicationRequest: ParameterRequest,
  manufacturingMethodsRequest: ParameterRequest,
  purposesRequest: ParameterRequest,
  positionRequest: ParameterRequest,
  equipmentTypesRequest: ParameterRequest,
  imagesRequest: ParameterRequest,
  schemesRequest: ParameterRequest,
}

export type ParameterRequest = {
  replicate: boolean
}

const initialState: ReplicateDescriptionDialogState = {
  open: false,
  replicationStatus: 'idle',
  descriptions: replicateDescriptionAdapter.getInitialState(),
  selectedDescriptions: selectedReplicateDescriptionAdapter.getInitialState(),
  request: {
    materialsReplicationRequest: { replicate: false },
    manufacturingMethodsRequest: { replicate: false },
    purposesRequest: { replicate: false },
    positionRequest: { replicate: false },
    equipmentTypesRequest: { replicate: false },
    imagesRequest: { replicate: false },
    schemesRequest: { replicate: false },
  },
}

export const replicateUnit = createAsyncThunk(
  'replicate-unit',
  async (request: { unitId: number, replicateUnitId: number, replicationRequest: ReplicationRequest }) => {
    const result = await authService.getIdentity()
    const {
      unitId,
      replicateUnitId,
      replicationRequest,
    } = request
    if (result) {
      return await msalFetch()
        .post(`${REPLICATE_API_ADDRESS}/${unitId}/replicate`, result.accessToken, {
          unitId: replicateUnitId,
          materialsReplicationRequest: replicationRequest.materialsReplicationRequest,
          manufacturingMethodsRequest: replicationRequest.manufacturingMethodsRequest,
          purposesRequest: replicationRequest.purposesRequest,
          positionRequest: replicationRequest.positionRequest,
          equipmentTypesRequest: replicationRequest.equipmentTypesRequest,
          imagesRequest: replicationRequest.imagesRequest,
          schemesRequest: replicationRequest.schemesRequest,
        })
        .then(handleErrors)
        .then<UnitTechnicalDescription>(async (res) => await res.json())
    }
  },
)

export const multiReplicateUnit = createAsyncThunk(
  'multi-replicate-unit',
  async (request: { unitIds: number[], replicateUnitId: number, replicationRequest: ReplicationRequest }) => {
    const result = await authService.getIdentity()
    const {
      unitIds,
      replicateUnitId,
      replicationRequest,
    } = request
    if (result) {
      return await msalFetch()
        .post(`${REPLICATE_API_ADDRESS}/replicate`, result.accessToken, {
          unitIds: unitIds,
          replicationUnitId: replicateUnitId,
          materialsReplicationRequest: replicationRequest.materialsReplicationRequest,
          manufacturingMethodsRequest: replicationRequest.manufacturingMethodsRequest,
          purposesRequest: replicationRequest.purposesRequest,
          positionRequest: replicationRequest.positionRequest,
          equipmentTypesRequest: replicationRequest.equipmentTypesRequest,
          imagesRequest: replicationRequest.imagesRequest,
          schemesRequest: replicationRequest.schemesRequest,
        })
        .then(handleErrors)
        .then<UnitTechnicalDescription[]>(async (res) => await res.json())
    }
  },
)

export const replicateDescriptionSlice = createSlice({
  name: 'replicateDescription',
  initialState: initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    addDescription: (
      state, action: PayloadAction<SelectableDescription>) => {
      replicateDescriptionAdapter.removeAll(state.descriptions)
      replicateDescriptionAdapter.addOne(
        state.descriptions,
        action.payload)
    },
    removeDescriptions: (state) => {
      selectedReplicateDescriptionAdapter.removeAll(state.selectedDescriptions)
    },
    selectDescriptions: (state) => {
      state.selectedDescriptions = state.descriptions
    },
    setMaterialsReplicationRequest: (
      state, action: PayloadAction<boolean>) => {
      state.request.materialsReplicationRequest.replicate = action.payload
    },
    setManufacturingMethodsRequest: (
      state, action: PayloadAction<boolean>) => {
      state.request.manufacturingMethodsRequest.replicate = action.payload
    },
    setPurposesRequest: (
      state, action: PayloadAction<boolean>) => {
      state.request.purposesRequest.replicate = action.payload
    },
    setPositionRequestRequest: (
      state, action: PayloadAction<boolean>) => {
      state.request.positionRequest.replicate = action.payload
    },
    setEquipmentTypesRequest: (
      state, action: PayloadAction<boolean>) => {
      state.request.equipmentTypesRequest.replicate = action.payload
    },
    setImagesRequest: (state, action: PayloadAction<boolean>) => {
      state.request.imagesRequest.replicate = action.payload
    },
    setSchemesRequest: (state, action: PayloadAction<boolean>) => {
      state.request.schemesRequest.replicate = action.payload
    },
  },
  extraReducers: (build) => {
    build.addCase(replicateUnit.pending, (state, action) => {
      state.replicationStatus = 'pending'
    })
    build.addCase(replicateUnit.fulfilled, (state, action) => {
      state.replicationStatus = 'idle'
    })
    build.addCase(replicateUnit.rejected, (state, action) => {
      state.replicationStatus = 'idle'
    })
    build.addCase(multiReplicateUnit.pending, (state, action) => {
      state.replicationStatus = 'pending'
    })
    build.addCase(multiReplicateUnit.fulfilled, (
      state, action) => {
      state.replicationStatus = 'idle'
    })
    build.addCase(multiReplicateUnit.rejected, (state, action) => {
      state.replicationStatus = 'idle'
    })

  },
})

export const replicateDescriptionSelectors = replicateDescriptionAdapter.getSelectors<RootState>(
  (state: RootState) => state.logistics.replicateDescription.selectedDescriptions,
)

export const {
  setOpen,
  selectDescriptions,
  setMaterialsReplicationRequest,
  setManufacturingMethodsRequest,
  setPurposesRequest,
  setPositionRequestRequest,
  setEquipmentTypesRequest,
  setImagesRequest,
  setSchemesRequest,
} = replicateDescriptionSlice.actions

export const selectReplicateDescriptions = replicateDescriptionSelectors.selectAll
export const selectReplicateDescriptionsState = (state: RootState) => state.logistics.replicateDescription
export const selectReplicateDescriptionsRequest = (state: RootState) => state.logistics.replicateDescription.request
export const selectReplicateDescriptionsStatus = (state: RootState) => state.logistics.replicateDescription.replicationStatus
