import {
  Grid,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  type FC,
  type ReactNode,
} from 'react'

import { logisticsTheme } from 'app/config/theme/logisticsTheme'
import { useAppSelector } from 'app/store'

import {
  CheckFields,
  type CheckFieldsSet,
  descriptionFieldChecksSelectors,
  type DocumentFieldCheck,
} from 'features/logistics/downloadTechnicalDescriptions'
import { SetTechnicalDescriptionAcceptanceStatus } from 'features/logistics/setTechnicalDescriptionAcceptanceStatus'
import {selectReplicateDescriptionsStatus} from 'features/logistics/unitDescription/replicateDescription/replicateDescriptionSlice'

import {
  CatalogNames,
  type CatalogRecord,
} from 'entities/logistics/Catalog'
import {
  technicalCardsApi,
  type UpdateEquipmentTypeData,
  type UpdateManufacturingMethodsData,
  type UpdateMaterialsData,
  type UpdatePurposesData,
} from 'entities/logistics/TechnicalCard/model/technicalCardsApi'
import {
  TechnicalCardApproveState,
  type UnitTechnicalDescription,
} from 'entities/logistics/TechnicalCard/model/types'

import {
  useIfPermittedComponent,
  useIsUserHavePermission,
} from 'shared/lib/checkPermissions'
import { useGetUnitApproveState } from 'shared/lib/logistics'
import {useMobileBreakpoint} from "shared/lib/useMobileBreakpoint";
import { themeSpacingFromPixels } from 'shared/lib/workWithThemeSpacing'
import {
  PermissionsLogisticActions,
  PermissionsLogisticSection,
  PermissionsModule,
} from 'shared/model/contracts/permissions'
import {
  EditableAreaTextFieldWithActions,
  EditableMultipleSelectItemsField,
  EditableSingleSelectItemsField,
} from 'shared/ui/components/EditableFields'

interface UnitAdditionalInfoProps {
  unit: UnitTechnicalDescription
  actionsSlot?: ReactNode[]
}

function useGetErrorFieldKeys (unitId: number) {
  const errorInFields: DocumentFieldCheck | undefined = useAppSelector(
    state => descriptionFieldChecksSelectors.selectById(state, unitId))
  const errorFields: CheckFieldsSet | undefined = errorInFields?.fieldCheckCorrect

  let errorFieldKeys: CheckFields[] = []
  if (typeof errorFields !== 'undefined') {
    errorFieldKeys = Object.keys(errorFields)
      .filter((key) =>
        errorFields
        // @ts-ignore
          ? !errorFields[key]
          : false,
      ) as CheckFields[]
  }
  return errorFieldKeys
}

function isFieldWithError (errorFieldKeys: CheckFields[], fieldType: CheckFields) {
  return Boolean(errorFieldKeys.find(i => i === fieldType))
}

function MaterialsInfo (props: {
  unit: UnitTechnicalDescription,
  readonly: boolean,
  errorFieldKeys: CheckFields[],
  changeDataHandler: (data: CatalogRecord[]) => void
}) {
  return <EditableMultipleSelectItemsField
    fieldName={'Материалы'}
    unit={props.unit}
    readonly={props.readonly}
    apiCatalogName={CatalogNames.materials}
    warning={isFieldWithError(props.errorFieldKeys, CheckFields.materials)}
    changeDataHandler={props.changeDataHandler}
  />
}

function PurposesInfo (props: {
  unit: UnitTechnicalDescription,
  readonly: boolean,
  errorFieldKeys: CheckFields[],
  changeDataHandler: (data: string) => void
}) {
  return <EditableAreaTextFieldWithActions
    fieldName={'Назначение'}
    unit={props.unit}
    readonly={props.readonly}
    unitFieldName={'purpose'}
    title={'Назначение'}
    changeDataHandler={props.changeDataHandler}
    warning={isFieldWithError(props.errorFieldKeys, CheckFields.purpose)}
  />
}

function ManufacturingMethodInfo (props: {
  unit: UnitTechnicalDescription,
  readonly: boolean,
  errorFieldKeys: CheckFields[],
  changeDataHandler: (data: CatalogRecord[]) => void
}) {
  return <EditableMultipleSelectItemsField
    fieldName={'Способ изготовления'}
    unit={props.unit}
    apiCatalogName={CatalogNames.manufacturingMethods}
    readonly={props.readonly}
    warning={isFieldWithError(props.errorFieldKeys, CheckFields.manufacturingMethods)}
    changeDataHandler={props.changeDataHandler}
  />
}

function EquipmentTypeInfo (props: {
  unit: UnitTechnicalDescription,
  readonly: boolean,
  errorFieldKeys: CheckFields[],
  changeDataHandler: (data: CatalogRecord) => void
}) {
  return <EditableSingleSelectItemsField
    fieldName={'Тип оборудования'}
    unit={props.unit}
    readonly={props.readonly}
    apiCatalogName={CatalogNames.equipmentType}
    warning={isFieldWithError(props.errorFieldKeys, CheckFields.equipmentType)}
    changeDataHandler={props.changeDataHandler}
  />
}

export const UnitAdditionalInfo: FC<UnitAdditionalInfoProps> = ({
  unit,
  actionsSlot,
}) => {

  const mobileBreakpoint = useMobileBreakpoint()

  const [updateEquipmentType] = technicalCardsApi.useUpdateEquipmentTypeMutation()
  const [updateManufacturingMethods] = technicalCardsApi.useUpdateManufacturingMethodsMutation()
  const [updateMaterialsUnit] = technicalCardsApi.useUpdateMaterialsUnitMutation()
  const [updatePurposesUnit] = technicalCardsApi.useUpdatePurposesUnitMutation()
  const replicateStatus = useAppSelector(selectReplicateDescriptionsStatus)
  const isLoading = replicateStatus === "pending"

  const errorFieldKeys = useGetErrorFieldKeys(unit.unitId||0)

  const currentApproveState = useGetUnitApproveState(unit.unitId)
  const isReadOnlyFields = !currentApproveState || currentApproveState===TechnicalCardApproveState.Approved

  return (

    <Grid
      data-testid="unit-additional-info"
      container
      sx={{
        border: `1px solid ${logisticsTheme.palette.primary.main}`,
        borderRadius: '12px',
      }}
    >

      <Grid
        item
        xs={12}
        sx={{
          borderBottom: `1px solid ${logisticsTheme.palette.primary.main}`,
          borderRadius: themeSpacingFromPixels(10, 10, 0, 0),
          backgroundColor: '#7858A61F',
          color: logisticsTheme.palette.primary.main,
          padding: '25px 32px 20px 32px',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Stack direction={'column'}>
            <Typography variant="h1">Info</Typography>
            <Typography variant="body1">More data</Typography>
          </Stack>
          {useIfPermittedComponent(
            <SetTechnicalDescriptionAcceptanceStatus
              unitID={unit.unitId}
              loading={isLoading}
            />,
            useIsUserHavePermission(
              PermissionsModule.Logistics,
              PermissionsLogisticSection.Units,
              PermissionsLogisticActions.ChangeState))
          }
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ padding: '25px 32px 20px 32px' }}
      >
        <Stack direction={'column'}>
          <MaterialsInfo
            unit={unit}
            readonly={isReadOnlyFields || isLoading}
            errorFieldKeys={errorFieldKeys}
            changeDataHandler={(data: CatalogRecord[]) => {
              // @ts-ignore
              const updateData: UpdateMaterialsData = {
                unitId: unit.unitId,
                materialIds: data.map(v => v.id),
              }
              updateMaterialsUnit(updateData)
            }}
          />
          <ManufacturingMethodInfo
            unit={unit}
            readonly={isReadOnlyFields || isLoading}
            errorFieldKeys={errorFieldKeys}
            changeDataHandler={(data: CatalogRecord[]) => {
              // @ts-ignore
              const updateData: UpdateManufacturingMethodsData = {
                unitId: unit.unitId,
                manufacturingMethodsIds: data.map(v => v.id),
              }
              updateManufacturingMethods(updateData)
            }}
          />
          <EquipmentTypeInfo
            unit={unit}
            readonly={isReadOnlyFields || isLoading}
            errorFieldKeys={errorFieldKeys}
            changeDataHandler={(data: CatalogRecord) => {
              // @ts-ignore
              const updateData: UpdateEquipmentTypeData = {
                unitId: unit.unitId,
                equipmentTypeId: data.id,
              }
              updateEquipmentType(updateData)
            }}
          />
          <PurposesInfo
            unit={unit}
            errorFieldKeys={errorFieldKeys||[]}
            readonly={isReadOnlyFields || isLoading}
            changeDataHandler={async (data: string) => {
              // @ts-ignore
              const updateData: UpdatePurposesData = {
                unitId: unit.unitId,
                purpose: data,
              }
              updatePurposesUnit(updateData)
            }}
          />

          <Stack
            direction={mobileBreakpoint
              ? 'column'
              :'row'}
            gap={themeSpacingFromPixels(8)}
          >
            {!!actionsSlot && actionsSlot}
          </Stack>
        </Stack>
      </Grid>
    </Grid>

  )
}
