import { z } from 'zod'

import { schemas } from 'shared/model/commonLogisticApiSchemes'

export enum TechnicalCardApproveState {
  Draft    = 'Draft',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export const unitForecastValuesSchema = z.object({
  unitId: z.number(),
  equipmentTypes: z.array(schemas.EquipmentTypeDto.nullable()),
  materials: z.array(schemas.MaterialDto.nullable()),
  manufacturingMethods: z.array(schemas.ManufacturingMethodDto.nullable()),
  principles: z.array(schemas.PrincipleDto.nullable()),
})

const ApprovedAnalogDto = z
  .object({
    id: z.number().int(),
    elementId: z.string().nullable(),
    elementName: z.string().nullable(),
    elementNameRus: z.string().nullable(),
    oemArticleName: z.string().nullable(),
  })
  .partial()

export const unitAnalogsSchema = z.object({
  unitId: z.number(),
  analogs: z.array(ApprovedAnalogDto),
  identicals: z.array(ApprovedAnalogDto),
})

export type UnitTechnicalDescription = z.infer<typeof schemas.LogisticUnitDto>
export type UnitForecastValues = z.infer<typeof unitForecastValuesSchema>
export type ApprovedAnalogsResponse = z.infer<typeof unitAnalogsSchema>
export type ApprovedAnalog = z.infer<typeof ApprovedAnalogDto>

export enum TechnicalDescriptionImageType {
  image  = 'image',
  scheme = 'scheme',
}

export type TechnicalDescriptionImage = z.infer<typeof schemas.ImageDto>
export type TechnicalDescriptionScheme = z.infer<typeof schemas.SchemeDto>
export type Material = z.infer<typeof schemas.MaterialDto>
export type ManufacturingMethod = z.infer<typeof schemas.ManufacturingMethodDto>
export type Principle = z.infer<typeof schemas.PrincipleDto>
export type Content = z.infer<typeof schemas.ChildUnitDto>
export type Purpose = z.infer<typeof schemas.PurposeDto>
