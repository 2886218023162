import { nanoid } from 'nanoid/non-secure'
import { Suspense } from 'react'

import {
  selectSettings,
  useAppSelector,
} from 'app/store'
import {
  checkPermission,
  selectMe,
} from 'app/store/user/userSlice'

import MaintenancePage from 'widgets/errors/MaintenancePage'
import NoUserPermissionsView from 'widgets/NoUserPermissionsView'

import { type EditSettingsState } from '../../app/store/administration/settingsSlice'
import LoadingPageView from '../../pages/LoadingPageView'
import { SettingsUnderMaintenance } from '../api/settings'
import {
  AvailableEntitiesAction,
  AvailableModuleSection,
  type PermissionActions,
  PermissionsAdministrationEdit,
  type PermissionSection,
  type PermissionsLogisticActions,
  type PermissionsLogisticSection,
  type PermissionsModule,
} from '../model/contracts/permissions'
import { StyledNavLink } from '../ui/components/StyledNavLink'

const getPermissionStringRepresentation = (
  module: PermissionsModule, entity: PermissionSection, action: PermissionActions) => {
  try {
    // @ts-ignore
    const askingPermissionModuleSection: typeof PermissionsLogisticSection = AvailableModuleSection[module]
    // @ts-ignore
    const askingPermissionEntity = askingPermissionModuleSection[entity]
    // @ts-ignore
    const askingPermissionAction = AvailableEntitiesAction[askingPermissionEntity][action]

    return ['Permissions', module, askingPermissionEntity, askingPermissionAction].join('.')
  } catch (e) {
    console.log(`Cant find user permissions for ${module}.${entity}.${action}`)
    return ''
  }
}

export function useIsUserHavePermission (
  module: PermissionsModule, section: PermissionSection, action: PermissionsLogisticActions) {
  const stringPermission = getPermissionStringRepresentation(module, section, action)
  return useAppSelector((root) => checkPermission(root, stringPermission))
}

export const useGetAccessibleComponent = (component: JSX.Element, havePermission = true): JSX.Element => {

  const isAdmin = useAppSelector((root) => checkPermission(root, PermissionsAdministrationEdit))
  const settingsState: EditSettingsState = useAppSelector(selectSettings)
  const meState = useAppSelector(selectMe)

  let isUnderMaintenance = false

  if (settingsState) {
    const underMaintenanceSettings = settingsState.settings.filter((s) => s.name === SettingsUnderMaintenance)[0]
    if (underMaintenanceSettings) {
      isUnderMaintenance = underMaintenanceSettings.value === 'true'
    }
  }

  const settingsStatus = settingsState.status
  const meStatus = meState.status

  if (settingsStatus === 'loading' || meStatus === 'loading') {
    return <LoadingPageView/>
  }

  if (isUnderMaintenance && !isAdmin) {
    return <MaintenancePage/>
  }

  if (havePermission || isAdmin) {
    return (
      <Suspense
        key={`${nanoid()}`}
        fallback={<LoadingPageView/>}
      >
        {component}
      </Suspense>
    )
  }

  return <NoUserPermissionsView/>
}

export const useIfPermittedComponent = (component: JSX.Element, havePermission = true): JSX.Element | null => {

  const isAdmin = useAppSelector((root) => checkPermission(root, PermissionsAdministrationEdit))

  if (havePermission || isAdmin) {
    return (
      <Suspense
        key={`${nanoid()}`}
        fallback={<></>}
      >
        {component}
      </Suspense>
    )
  }
  return null
}
