import { Box } from '@mui/material'
import {
  type FC,
  ReactNode,
  useState, useEffect,
} from 'react'

import { useAppSelector } from 'app/store/hooks'

import { SelectPurposes } from 'features/logistics/selectPurposes'
import { type SelectableDescription } from 'features/logistics/units/searchUnts'
import { selectDescriptionsOnSearch } from 'features/logistics/units/searchUnts/model/selectableDescriptions'

import { AvailableUnitCatalogFields } from 'entities/logistics/Catalog/model/types'
import {
  type UnitTechnicalDescription,
  useGetUnitDescriptionsQuery,
} from 'entities/logistics/TechnicalCard'

import { TextAreaWindowWithActions } from '../TextAreaWindowWithActions'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableAreaTextFieldWithActionsProps {
  fieldName: string
  readonly?: boolean
  unit: UnitTechnicalDescription
  unitFieldName: AvailableUnitCatalogFields | 'purpose'
  warning?: boolean
  title?: string
  changeDataHandler: (data: any) => void
  actionsSlot?: ReactNode[]
}

export const EditableAreaTextFieldWithActions: FC<EditableAreaTextFieldWithActionsProps> = ({
  fieldName,
  unit,
  readonly,
  unitFieldName,
  warning = false,
  changeDataHandler,
  title = '',
}) => {
  const [currentContent, setCurrentContent] = useState<string>(String(unit[unitFieldName] || ''))

  useEffect(() => {
    if (unit) {
      setCurrentContent(String(unit[unitFieldName] || ''))
    }
  }, [unit])

  const [onEditing, setOnEditing] = useState(false)

  return (
    <>
      <EditableFieldTextData
        fieldName={fieldName}
        warning={warning}
        readonly={readonly}
        onClick={
          () => {
            setOnEditing(true)
          }
        }
        dataValue={String(unit[unitFieldName] || '')}
      />
      {onEditing &&
        <Box sx={{ width: 'auto' }}>
          <TextAreaWindowWithActions
            open={onEditing}
            onCancel={() => {
              setOnEditing(false)
            }}
            onOK={(data: string | undefined) => {
              changeDataHandler(data)
              setOnEditing(false)
            }}
            title={title}
            content={currentContent}
            setCurrentContent={setCurrentContent}
            actionsSlot={[
              <SelectPurposes
                unitId={unit.unitId}
                currentContent={currentContent}
                setCurrentContent={setCurrentContent}
              />,
            ]}
          />
        </Box>
      }
    </>
  )
}
