import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import {
  descriptionFieldChecksSelectors,
  type DocumentFieldCheck,
} from 'features/logistics/downloadTechnicalDescriptions'
import {setIsEmptyFields, setIsNoEmptyFields} from 'features/logistics/downloadTechnicalDescriptions/model/isEmptyFieldsInDescriptions'

const unitsWithFieldsError = (unitDataFields: DocumentFieldCheck[]) => {
  return unitDataFields.filter(unitData => isErrorUnitFieldCheck(unitData))
}

const isErrorUnitFieldCheck = (data: DocumentFieldCheck) => {
  const fieldsStatus = data.fieldCheckCorrect
  let isError = false

  Object.keys(fieldsStatus).forEach((key) => {
    // @ts-ignore
    if (!fieldsStatus[key]) {
      isError = true
    }
  })
  return isError
}

const useSetIsHaveErrorFields = () => {

  const dispatch = useAppDispatch()
  const fieldChecks: DocumentFieldCheck[] = useAppSelector(descriptionFieldChecksSelectors.selectAll)

  if (unitsWithFieldsError(fieldChecks).length) {
    dispatch(setIsEmptyFields())
  } else {
    dispatch(setIsNoEmptyFields())
  }

}

export default useSetIsHaveErrorFields
