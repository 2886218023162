import {
  type FC,
  useState,
} from 'react'

import {
  type CatalogNames,
  type CatalogRecord,
} from 'entities/logistics/Catalog'
import { UseCatalogCRUDApiHooks } from 'entities/logistics/Catalog/api/useCatalogCRUDApiHooks'
import { AvailableUnitCatalogFields } from 'entities/logistics/Catalog/model/types'
import { type UnitTechnicalDescription } from 'entities/logistics/TechnicalCard'

import { SelectSingleItem } from '../SelectDialogs/SelectSingleItem'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableSingleSelectItemsFieldProps {
  fieldName: string
  warning?: boolean
  readonly?: boolean,
  unit: UnitTechnicalDescription
  apiCatalogName: AvailableUnitCatalogFields
  changeDataHandler: (data: any) => void
}

export const EditableSingleSelectItemsField: FC<EditableSingleSelectItemsFieldProps> = ({
  fieldName,
  unit,
  readonly,
  warning = false,
  apiCatalogName,
  changeDataHandler,
}) => {

  const {
    getRecords,
  } = UseCatalogCRUDApiHooks(apiCatalogName as CatalogNames)
  const [onEditing, setOnEditing] = useState(false)

  const selectedValue = unit[apiCatalogName] as CatalogRecord | undefined

  const selectedRecordsTextsPresentation = selectedValue?.value || ''

  return (
    <>
      {onEditing &&
        <SelectSingleItem
          title={fieldName}
          catalogValues={getRecords.currentData}
          selectedValue={selectedValue}
          onSaveAction={
            (data: CatalogRecord | undefined) => {
              if (data !== selectedValue) {
                changeDataHandler(data)
              }
              setOnEditing(false)
            }
          }
        />
      }
      <EditableFieldTextData
        warning={warning}
        readonly={readonly}
        fieldName={fieldName}
        dataValue={selectedRecordsTextsPresentation}
        onClick={
          () => {
            setOnEditing(true)
          }
        }
      />
    </>
  )
}
