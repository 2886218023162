import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { DescriptionFieldChecksSlice } from 'features/logistics/downloadTechnicalDescriptions'
import {IsEmptyFieldsInDescriptionsSlice} from 'features/logistics/downloadTechnicalDescriptions/model/isEmptyFieldsInDescriptions'
import {
  FetchedDescriptionsSlice,
  MarkedDescriptionsSlice,
  searchDialogSlice,
} from 'features/logistics/units/searchUnts'

import { qaImageGallerySlice } from '../../features/logistics/openQAImageGallery'
import {replicateDescriptionSlice} from '../../features/logistics/unitDescription/replicateDescription/replicateDescriptionSlice'
import searchAnalogsSlice from '../../widgets/technicalDescriptions/Descriptions/searchAnalogsDialog/searchAnalogsSlice'

const persistSearchAnalogsConfig = {
  key: 'searchAnalogs',
  storage,
  blacklist: ['open', 'status', 'error'],
}

export const logistics = combineReducers({
  searchDescriptions: searchDialogSlice.reducer,
  fetchedDescriptions: FetchedDescriptionsSlice.reducer,
  markedDescriptions: MarkedDescriptionsSlice.reducer,
  qaImageGallery: qaImageGallerySlice.reducer,
  descriptionFieldChecks: DescriptionFieldChecksSlice.reducer,
  isEmptyFieldsInDescriptionsSlice: IsEmptyFieldsInDescriptionsSlice.reducer,
  replicateDescription: replicateDescriptionSlice.reducer,
  searchAnalogs: persistReducer(persistSearchAnalogsConfig, searchAnalogsSlice),
})
