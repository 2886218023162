import FindReplaceIcon from '@mui/icons-material/FindReplace'
import { IconButton, type SxProps, type Theme, Tooltip } from '@mui/material'
import { type FC } from 'react'

import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

interface Props {
  sx?: SxProps<Theme>
  setOpen?: (open: boolean) => void,
}

export const SearchAnalogsButton: FC<Props> = ({
  sx: sxOverwrite,
  setOpen,

}) => {

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    setOpen && setOpen(true)
  }

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        title={'Искать утвержденные аналоги'}
      >
        <IconButton
          sx={{
            ml: 0.5,
            height: themeSpacingFromPixels(28),
            ...sxOverwrite,

          }}
          aria-label="search analogs"
          onClick={(e) => {
            handleClick(e)
          }}
          edge="start"
        >
          <FindReplaceIcon sx={{
            pointerEvents: 'auto',
            width: '20px',
            height: '30px',
            ...sxOverwrite,
          }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}
