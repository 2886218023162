import {
  type Action,
  combineReducers,
  configureStore,
  type PreloadedState,
  type ThunkAction,
} from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { qaPreviewDataApi } from 'features/logistics/openQAImageGallery'

import {
  equipmentTypesApi,
  manufacturingMethodsApi,
  materialsApi,
  purposesApi,
} from 'entities/logistics/Catalog'
import { technicalCardsApi } from 'entities/logistics/TechnicalCard'

import { CURRENT_BUILD } from 'shared/api/api'
import { unitPicturesApi } from 'shared/api/unitImagesApi'
import { clearPersistKeys } from 'shared/lib/useLocalStorage'

import { administration } from './administration/redusers'
import applicationInformation from './application/applicationInformationSlice'
import { logistics } from './logisticsSlice'
import { units } from './units/reducers'
import user from './user/userSlice'

const persistRootConfig = {
  key: 'root',
  storage,
  whitelist: ['logistics'],
}

const persistLogisticsConfig = {
  key: 'logistics',
  storage,
  blacklist: ['searchAnalogs'],
}

const rootReducer = combineReducers(
  {
    units,
    user,
    administration,
    logistics: persistReducer(persistLogisticsConfig, logistics),
    applicationInformation,
    [materialsApi.reducerPath]: materialsApi.reducer,
    [purposesApi.reducerPath]: purposesApi.reducer,
    [manufacturingMethodsApi.reducerPath]: manufacturingMethodsApi.reducer,
    [equipmentTypesApi.reducerPath]: equipmentTypesApi.reducer,
    [technicalCardsApi.reducerPath]: technicalCardsApi.reducer,
    [unitPicturesApi.reducerPath]: unitPicturesApi.reducer,
    [qaPreviewDataApi.reducerPath]: qaPreviewDataApi.reducer,
  },
)

const currentBuild = Number(CURRENT_BUILD)
const localBuild = Number(localStorage.getItem('ec-build'))

clearPersistKeys(() => localBuild < currentBuild, ['root', 'items', 'list'])
localStorage.setItem('ec-build', currentBuild.toString())

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
          ],
        },
      },
    )
      .concat(unitPicturesApi.middleware)
      .concat(materialsApi.middleware)
      .concat(purposesApi.middleware)
      .concat(manufacturingMethodsApi.middleware)
      .concat(equipmentTypesApi.middleware)
      .concat(technicalCardsApi.middleware)
      .concat(qaPreviewDataApi.middleware),
})

export const persistor = persistStore(store)

// store helper for jest integration testing https://redux.js.org/usage/writing-tests#setting-up-a-reusable-test-render-function
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(
        {
          serializableCheck: {
            ignoredActions: [
              FLUSH,
              REHYDRATE,
              PAUSE,
              PERSIST,
              PURGE,
              REGISTER,
            ],
          },
        },
      )
        .concat(unitPicturesApi.middleware)
        .concat(materialsApi.middleware)
        .concat(purposesApi.middleware)
        .concat(manufacturingMethodsApi.middleware)
        .concat(equipmentTypesApi.middleware)
        .concat(technicalCardsApi.middleware)
        .concat(qaPreviewDataApi.middleware),
    preloadedState,
  })
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type TestStore = ReturnType<typeof setupStore>
export type TestDispatch = TestStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>
