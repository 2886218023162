import { Box } from '@mui/material'
import {
  type FC,
  useState, useEffect,
} from 'react'

import { type CatalogRecord, CatalogNames } from 'entities/logistics/Catalog'
import { UseCatalogCRUDApiHooks } from 'entities/logistics/Catalog/api/useCatalogCRUDApiHooks'
import {type UnitTechnicalDescription} from 'entities/logistics/TechnicalCard'

import { SelectMultipleItems } from 'shared/ui/components/SelectDialogs/SelectMultipleItems'

import { AvailableUnitCatalogFields } from '../../../../entities/logistics/Catalog/model/types'
import { useGetUnitForecastValuesMutation } from '../../../../entities/logistics/TechnicalCard/model/technicalCardsApi'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableMultipleSelectItemsFieldProps {
  fieldName: string
  readonly?: boolean
  unit: UnitTechnicalDescription
  apiCatalogName: AvailableUnitCatalogFields
  warning?: boolean
  changeDataHandler: (data: any) => void
}

export const EditableMultipleSelectItemsField: FC<EditableMultipleSelectItemsFieldProps> = ({
  fieldName,
  unit,
  readonly,
  apiCatalogName,
  warning = false,
  changeDataHandler,
}) => {
  const [forecastValues, setforecastValues] = useState<CatalogRecord[] | undefined>(undefined)

  const {
    getRecords,
  } = UseCatalogCRUDApiHooks(apiCatalogName)
  const [onEditing, setOnEditing] = useState(false)

  const selectedValues: CatalogRecord[] | undefined = unit[apiCatalogName] as CatalogRecord[] | undefined

  const [
    getForecast, {
      data,
    },
  ] = useGetUnitForecastValuesMutation()

  useEffect(() => {
    if (data) {
      if (apiCatalogName === CatalogNames.materials) {
        const forecastValues: CatalogRecord[] | undefined = data['materials'] as CatalogRecord[] | undefined
        setforecastValues(forecastValues)
      }
      if (apiCatalogName === CatalogNames.manufacturingMethods) {
        const forecastValues: CatalogRecord[] | undefined = data['manufacturingMethods'] as CatalogRecord[] | undefined
        setforecastValues(forecastValues)
      }
    }
  }, [data])

  const selectedRecordsTextsPresentation: string = selectedValues
    ?.map((record: CatalogRecord) => record.value)
    .join(', ') || ''

  return (
    <>
      <EditableFieldTextData
        fieldName={fieldName}
        warning={warning}
        readonly={readonly}
        dataValue={selectedRecordsTextsPresentation}
        onClick={
          () => {
            setforecastValues(undefined)
            setOnEditing(true)
          }
        }
      />
      {onEditing &&
        <Box sx={{ width: 'auto' }}>
          <SelectMultipleItems
            catalogValues={getRecords.currentData}
            selectedValues={selectedValues}
            forecastValues={forecastValues}
            title={fieldName}
            onSaveAction={
              (data: CatalogRecord[] | undefined) => {
                setforecastValues(undefined)
                if (JSON.stringify(data) !== JSON.stringify(selectedValues)) {
                  changeDataHandler(data)
                }
                setOnEditing(false)
                setforecastValues(undefined)
              }
            }
            onForecastAction={
              () => {
                getForecast({ unitId: unit.unitId })
              }
            }
            checkIds={true}
          />
        </Box>
      }
    </>
  )
}
