import {
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { type RootState } from 'app/store/store'

interface IsEmptyFieldsInDescriptionsState {
  isEmptyFields: boolean
}

const initialState: IsEmptyFieldsInDescriptionsState = { isEmptyFields: false }

export const IsEmptyFieldsInDescriptionsSlice = createSlice({
  name: 'isEmptyFieldsInDescriptions',
  initialState,
  reducers: {
    setIsEmptyFields: (state) => {
      state.isEmptyFields = true
    },
    setIsNoEmptyFields: (state) => {
      state.isEmptyFields = false
    },
  },
})

const isEmptyFieldsInDescriptionsState = (state: RootState) => state.logistics.isEmptyFieldsInDescriptionsSlice

export const selectIsEmptyFieldsInDescriptions = createSelector(
  isEmptyFieldsInDescriptionsState,
  (s) => s.isEmptyFields)
export const {
  setIsEmptyFields,
  setIsNoEmptyFields,

} = IsEmptyFieldsInDescriptionsSlice.actions
