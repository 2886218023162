import {
  DialogTitle, Divider, Stack, Dialog, DialogActions, DialogContent, Collapse,
  Button, LinearProgress,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { FC, SyntheticEvent, useEffect, useState } from 'react'

import { useAppSelector, useAppDispatch } from '../../../../../app/store'
import { ApprovedAnalog, ApprovedAnalogsResponse } from '../../../../../entities/logistics/TechnicalCard/model/types'
import CopyDescriptionButton from '../../../../../features/logistics/CopyDescriptionButton'
import { API_ADDRESS } from '../../../../../shared/api/api'
import authService from '../../../../../shared/api/AuthService'
import { msalFetch } from '../../../../../shared/api/graph'
import { themeSpacingFromPixels } from '../../../../../shared/lib/workWithThemeSpacing'
import { StyledNavLink } from '../../../../../shared/ui/components/StyledNavLink'
import { selectOpenSearchAnalogs, setOpenAnalogs, selectUnitIdSearchAnalogs } from '../searchAnalogsSlice'

const UNITS_API_ADDRESS = `${API_ADDRESS}/el/units`

export const SearchAnalogDialog: FC = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [analogs, setAnalogs] = useState<ApprovedAnalog[] | undefined>(undefined)
  const [identicals, setIdenticals] = useState<ApprovedAnalog[] | undefined>(undefined)
  const opened = useAppSelector(selectOpenSearchAnalogs)
  const unitId = useAppSelector(selectUnitIdSearchAnalogs)
  const handleDialogClose = (_: SyntheticEvent, reason: string) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      dispatch(setOpenAnalogs(false))
    }
  }

  useEffect(() => {

    if (opened && unitId) {
      setLoading(true)
      authService
        .getIdentity()
        .then((result) => {
          msalFetch()
            .get(`${UNITS_API_ADDRESS}/${unitId}/analogs`, result.accessToken)
            .then<ApprovedAnalogsResponse>((res) => res.json())
            .then((res) => {
              try {
                if (res) {
                  setIdenticals(res.identicals)
                  setAnalogs(res.analogs)
                }
              } finally {
                setLoading(false)
              }
            })
        })

    }
  }, [opened, unitId])

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { maxWidth: themeSpacingFromPixels(450) } }}
      open={opened}
      onClose={handleDialogClose}
    >
      <DialogTitle>Выбор аналога для копирования</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={loading}>
          <LinearProgress color="primary"/>
        </Collapse>
        <Collapse in={!loading}>
          {identicals && analogs && identicals.length === 0 && analogs.length === 0 &&
            <Typography variant="body1">Утвержденных технических описаний не найдено</Typography>}
          <SimpleTreeView>
            {identicals && identicals.length > 0 &&
              <TreeItem
                itemId="identicals"
                label={`Identicals (${identicals.length})`}
                sx={{ margin: 1 }}
              >
                {identicals.map((i) => {
                  return (
                    <TreeItem
                      key={`identical-${i.id}`}
                      itemId={`identical-${i.id}`}
                      label={
                        <Stack
                          spacing={1}
                          direction={'row'}
                          alignItems={'center'}
                        >
                          <Stack direction={'column'}>
                            <StyledNavLink
                              key={i.id}
                              data-testid={`styled-navlink-${i.id}`}
                              to={`/logistic/technical-description?filter=${i.elementId}`}
                              onClick={(e) => {
                                e.stopPropagation()
                                // Странное поведение
                                // dispatch(setOpenAnalogs(false))
                              }}
                              style={{ flex: 'none' }}
                              target="_blank"
                            >
                              {i.elementId}
                            </StyledNavLink>
                            <Typography variant="body1">{i.elementNameRus}</Typography>
                          </Stack>
                          <CopyDescriptionButton
                            key={`replicate-${i.id}`}
                            unitId={i.id ?? 0}
                            elementId={i.elementId || ''}
                          />
                        </Stack>

                      }
                      sx={{ margin: 1 }}
                    />
                  )
                })}
              </TreeItem>
            }
            {analogs && analogs.length > 0 &&
              <TreeItem
                itemId="analogs"
                label={`Analogs (${analogs.length})`}
                sx={{ margin: 1 }}
              >
                {analogs.map((i) => {
                  return (
                    <TreeItem
                      key={`analog-${i.id}`}
                      itemId={`analog-${i.id}`}
                      label={
                        <Stack
                          spacing={1}
                          direction={'row'}
                          alignItems={'center'}
                        >
                          <Stack direction={'column'}>
                            <StyledNavLink
                              key={i.id}
                              data-testid={`styled-navlink-${i.id}`}
                              to={`/logistic/technical-description?filter=${i.elementId}`}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              style={{ flex: 'none' }}
                              target="_blank"
                            >
                              {i.elementId}
                            </StyledNavLink>
                            <Typography variant="body1">{i.elementNameRus}</Typography>
                          </Stack>
                          <CopyDescriptionButton
                            key={`replicate-${i.id}`}
                            unitId={i.id ?? 0}
                            elementId={i.elementId || ''}
                          />
                        </Stack>

                      }
                      sx={{ margin: 1 }}
                    />
                  )
                },
                )}
              </TreeItem>
            }
          </SimpleTreeView>
        </Collapse>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              dispatch(setOpenAnalogs(false))
            }}
          >
            Закрыть
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
