import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Stack } from '@mui/material'
import {
  type FC,
  ReactNode,
} from 'react'

type StatusIconProps = {
  status: string
}

const statusIcons: Record<string, ReactNode> = {
  ['Approved']: <CheckCircleOutlineIcon
    fontSize={'small'}
    color={'success'}
  />,
  ['Not approved']: <QuestionMarkIcon
    fontSize={'small'}
    color={'error'}
  />,
  ['Checking']: <DoDisturbIcon
    fontSize={'small'}
    color={'warning'}
  />,
}

export const StatusIcon: FC<StatusIconProps> = ({
  status,
}) => {

  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
    >
      {statusIcons[status]}
    </Stack>
  )
}
