import {
  type FC,
  type ReactNode,
} from 'react'
import { NavLink } from 'react-router-dom'

interface StyledNavLinkProps {
  to: string
  children: ReactNode
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  style?: React.CSSProperties
  target?: React.HTMLAttributeAnchorTarget | undefined
}

export const StyledNavLink: FC<StyledNavLinkProps> = ({
  to,
  children,
  onClick,
  style,
  target,
}) => {
  return <NavLink
    target={target}
    onClick={(e) => {
      e.stopPropagation()
      onClick && onClick(e)
    }}
    style={{
      display: 'flex',
      flex: 'auto',
      ...style,
      pointerEvents: 'auto',
    }}
    to={to}
    end
  >
    {children}
  </NavLink>
}
